/**
 * Order related by OJJ
 */
import Vue from "vue";
import firebase from "firebase/compat/app";
import moment from 'moment';
import database from "./database";

export default {
};

export const order = {
  install(Vue) {
    Vue.prototype.$order = {
      async saveOrderSetting(hospitalId, enableAutoOrder) {
        // update db
        await firebase.firestore().runTransaction(async (transaction) => {
          let ref = firebase.firestore().collection("hospitals").doc(hospitalId);
          transaction.update(ref, { enableAutoOrder: enableAutoOrder });
        });
      },
      async makeOrderData() {

      },
      async getOrderData(hospitalId, sellerId) {
        let orderItem = {};
        let ref = firebase.firestore()
          .collection('orders')
          .where('hospitalId', '==', hospitalId)
          .where('sellerId', '==', sellerId)
          .where('orderState', '==', 'OrderState.deliveryDone')
          .orderBy('createAt', 'desc');
        await ref.get()
          .then(async (querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              orderItem = querySnapshot.docs[0].data();
            }
          });
        return orderItem;
      },
      getQuotationItem: async function (id) {
        let item = null;
        await firebase
          .firestore()
          .collection("quotationItems")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      // /**
      //  * Create orderId (ORD0000...) from quotationId (EST0000...)
      //  * @param quotationId
      //  * @returns {Promise<*>}
      //  */
      // async makeOrderId(quotationId) {
      //   let allOrders = await database.loadOrdersByQuotationId(
      //     quotationId
      //   );
      //   return quotationId.replaceAll("EST","ORD") +
      //     this.$utils.lpad(allOrders.length + 1,"0",3);
      // },
    }
  }
}


Vue.use(order);