import Vue from "vue";
import firebase from "firebase/compat/app";
import firebaseConfig from "../../firebaseConfig";
import axios from "axios";

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default {};

export const functions = {
  install(Vue) {
    Vue.prototype.$functions = {
      call: async function (name, data) {
        var result = "";
        try {
          var callable = firebaseApp
            .functions("asia-northeast3")
            .httpsCallable(name);
          result = await callable(data);
        } catch (e) {
          console.log(e);
          console.log("call error", new Date());
        }
        return result;
      },
      callUS: async function (name, data) {
        var result = "";
        try {
          var callable = firebaseApp.functions().httpsCallable(name);
          result = await callable(data);
        } catch (e) {
          console.log(e);
          console.log("call error", new Date());
        }
        return result;
      },
      sendKakao: async function (data) {
        // kakao
        await axios
          .post(
            "https://us-central1-doctor365-9a68d.cloudfunctions.net/sendKakao",
            JSON.stringify(data),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async function (response) {
            console.log("sendKakao:", response);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    };
  },
};

Vue.use(functions);
