import Vue from "vue";
import colors from "vuetify/lib/util/colors";

let themeColors = {
  primary: "#69CBBC",
  secondary: "#495057",
  info: "#17c1e8",
  success: "#69CBBC",
  warning: "#fbcf33",
  danger: "#ea0606",
  light: "#e9ecef",
  accent: colors.red.darken3,

  lightGrey: "#AAAAAA",
  lightLightGrey: "#F5F5F5",
  darkGrey: "#495057",
  red: colors.red.darken3,
  lightGreen: "#E0F4F1",
};

export default themeColors;

export const theme = {
  install(Vue) {
    Vue.prototype.$theme = themeColors;
  },
};

Vue.use(theme);
